import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { AlertService } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html'
})
export class OrcamentoComponent implements OnInit {

  formulario: FormGroup;
  ramos = [
    "Comércio Atacadista",
    "Comércio Varejista",
    "Comércio Distribuidor",
    "Indústria",
    "Prestação de Serviços",
    "Transportadora",
    "Outros"
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private alert: AlertService
  ) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      email: [null, [Validators.required,Validators.email]],
      telefone: [null, [Validators.required]],
      empresa: [null, [Validators.required]],
      cnpj: [null, [Validators.required]],
      ramo: [null, [Validators.required]],
      usuarios: [null, [Validators.required]]
    });

  }

  onSubmit() {
    if (this.formulario.valid) {
      this.http.post('https://www.fingerp.com.br/api/?/site/orcamento', JSON.stringify(this.formulario.value))
        .subscribe((data: any) => {
          console.log(data);
          if (data.success) {
            this.bsModalRef.hide();
            this.alert.success(data.data);            
          } else {
            this.alert.error(data.message);
          }                    
        })
    } else {
      Object.keys(this.formulario.controls).forEach(campo => {
        const controle = this.formulario.get(campo);
        controle.markAsDirty();
        controle.markAsTouched();
      });
    }    
  }

  isInvalid(campo: string){
    return {
      'is-invalid': !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    }
  }

}
