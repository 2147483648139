<div class="modal-header">
    <h2 class="modal-title text-primary text-center">Solicitar <b>Orçamento</b></h2>
</div>
<div class="modal-body">
    <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="nome">Seu nome</label>
                <input type="text" class="form-control" id="nome" formControlName="nome" [ngClass]="isInvalid('nome')" />
                <div *ngIf="formulario.get('nome').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('nome').errors.required">Nome é obrigatório</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="cargo">Cargo</label>
                <input type="text" class="form-control" id="cargo" formControlName="cargo" [ngClass]="isInvalid('cargo')" />
                <div *ngIf="formulario.get('cargo').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('cargo').errors.required">Cargo é obrigatório</small>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="email">E-mail</label>
                <input type="mail" class="form-control" id="email" formControlName="email" [ngClass]="isInvalid('email')" />
                <div *ngIf="formulario.get('email').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('email').errors.required">E-mail é obrigatório</small>
                    <small *ngIf="formulario.get('email').errors.email" >E-mail está inválido</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="telefone">Telefone</label>
                <input type="text" class="form-control" id="telefone" formControlName="telefone" [ngClass]="isInvalid('telefone')" />
                <div *ngIf="formulario.get('telefone').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('telefone').errors.required">Telefone é obrigatório</small>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="empresa">Nome da empresa</label>
                <input type="text" class="form-control" id="empresa" formControlName="empresa" [ngClass]="isInvalid('empresa')" />
                <div *ngIf="formulario.get('empresa').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('empresa').errors.required">Empresa é obrigatório</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="cnpj">CNPJ</label>
                <input type="text" class="form-control" id="cnpj" formControlName="cnpj" [ngClass]="isInvalid('cnpj')" />
                <div *ngIf="formulario.get('cnpj').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('cnpj').errors.required">CNPJ é obrigatório</small>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="ramo">Ramo de atividade</label>
                <select class="form-control" id="ramo" formControlName="ramo" [ngClass]="isInvalid('ramo')">
                    <option *ngFor="let ramo of ramos" [value]="ramo">{{ ramo }}</option>
                </select>
                <div *ngIf="formulario.get('ramo').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('ramo').errors.required">Ramo de Atividade é obrigatório</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="usuarios">Quantidade de usuários</label>
                <input type="number" class="form-control" id="usuarios" formControlName="usuarios" min="1" max="999" [ngClass]="isInvalid('usuarios')" />
                <div *ngIf="formulario.get('usuarios').errors" class="invalid-feedback">
                    <small *ngIf="formulario.get('usuarios').errors.required">Quantidade de Usuário é obrigatória</small>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-lg btn-primary">Solicitar Orçamento</button>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">Cancelar</button>
        </div>
        
    </form>

</div>