<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Em que podemos <b>ajudá-lo</b>?</h2>
            <p class="text-secondary">Nossa equipe está sempre disposta a responder suas dúvidas.</p>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card border-0 my-5">
                <div class="card-body">
                    <h3 class="card-title text-center">Fale Conosco</h3>
                    <p class="card-text text-justify">Entre em contato conosco sobre qualquer coisa relacionada aos produtos, serviços ou nossa empresa.</p>
                    <form class="mt-5" [formGroup]="formulario" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="nome">Seu nome</label>
                            <input type="text" class="form-control" id="nome" formControlName="nome" [ngClass]="isInvalid('nome')">
                            <div *ngIf="formulario.get('nome').errors" class="invalid-feedback">
                                <small *ngIf="formulario.get('nome').errors.required">Nome é obrigatório</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="empresa">Nome da empresa</label>
                            <input type="text" class="form-control" id="empresa" formControlName="empresa" [ngClass]="isInvalid('empresa')">
                        </div>
                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input type="email" class="form-control" id="email" formControlName="email" [ngClass]="isInvalid('email')">
                            <div *ngIf="formulario.get('email').errors" class="invalid-feedback">
                                <small *ngIf="formulario.get('email').errors.required">E-mail é obrigatório</small>
                                <small *ngIf="formulario.get('email').errors.email">E-mail está inválido</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="telefone">Telefone</label>
                            <input type="text" class="form-control" id="telefone" formControlName="telefone" [ngClass]="isInvalid('telefone')">
                        </div>              
                        <div class="form-group">
                            <label for="mensagem">Mensagem</label>
                            <textarea class="form-control" id="mensagem" formControlName="mensagem" rows="5" [ngClass]="isInvalid('mensagem')"></textarea>
                            <div *ngIf="formulario.get('mensagem').errors" class="invalid-feedback">
                                <small *ngIf="formulario.get('mensagem').errors.required">Mensagem é obrigatória</small>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-lg btn-block btn-primary">Enviar Mensagem</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center mb-5">
        <img src="assets/img-contato.jpg"  class="img-fluid" alt="Contato">
    </div>
</div>

<div class="section bg-light text-center">
    <div class="container">
        <h3>Nossa Localização</h3>
        <p>Nossa empresa está localizada na cidade de Içara em Santa Catarina</p>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d431.1170602695024!2d-49.29959577979298!3d-28.716505948805267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95217e9420815473%3A0x5e7d34f167c837f9!2sFing%20Sistemas!5e0!3m2!1spt-BR!2sbr!4v1584994047046!5m2!1spt-BR!2sbr" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
</div>

<app-solicitar-orcamento></app-solicitar-orcamento>