import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { CurriculoComponent } from '../curriculo/curriculo.component';

@Component({
  selector: 'app-carreiras',
  templateUrl: './carreiras.component.html'
})
export class CarreirasComponent implements OnInit {

  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  enviarCurriculo() {
    this.bsModalRef = this.modalService.show(CurriculoComponent);
  }

}
