import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  loading: boolean;

  constructor(
    public router:Router
  ) {
    this.loading = false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
        gtag('config', 'UA-45735015-1', {'page_path': event.urlAfterRedirects.split('#')[0]});
      }
    });
  }  

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      if (document.getElementsByClassName('navbar').length>0) document.getElementsByClassName('navbar')[0].classList.add('fixed-top');
      if (document.getElementsByClassName('content').length>0) document.getElementsByClassName('content')[0].classList.add('content-fixed-top');
    } else {
      if (document.getElementsByClassName('navbar').length>0) document.getElementsByClassName('navbar')[0].classList.remove('fixed-top');
      if (document.getElementsByClassName('content').length>0) document.getElementsByClassName('content')[0].classList.remove('content-fixed-top');
    }
  }
  
}
