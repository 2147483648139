import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from '../shared/shared.module';
import { CarreirasComponent } from './carreiras/carreiras.component';
import { ContatoComponent } from './contato/contato.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { ServicosComponent } from './servicos/servicos.component';
import { SobreComponent } from './sobre/sobre.component';
import { HomeComponent } from './home/home.component';
import { OrcamentoComponent } from './orcamento/orcamento.component';
import { CurriculoComponent } from './curriculo/curriculo.component';
import { SolucoesComponent } from './solucoes/solucoes.component';

@NgModule({
  declarations: [
    CarreirasComponent,
    ContatoComponent,
    ProdutosComponent,
    ServicosComponent,    
    SobreComponent,
    HomeComponent,
    OrcamentoComponent,
    CurriculoComponent,
    SolucoesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SharedModule    
  ]
})
export class PagesModule { }
