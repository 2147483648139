<div class="modal-header">
    <h2 class="modal-title text-primary">Enviar Curriculo</h2>
</div>
<div class="modal-body">
    <div class="mt-5 custom-file">
        <input type="file" class="custom-file-input" id="customFile" (change)="onChange($event)">
        <label class="custom-file-label" for="customFile" id="customFileLabel">{{ fileName }}</label>
    </div>
    <div class="mt-5 text-center">
        <button type="button" class="btn btn-lg btn-primary" (click)="onUpload()" [disabled]="!file">Enviar Curriculo</button>
    </div>
    <div class="text-center">
        <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">Cancelar</button>
    </div>
</div>