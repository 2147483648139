<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Política de <b>Privacidade</b></h2>
            <p class="text-secondary">A sua privacidade é importante para nós.</p>
        </div>
    </div>
</div>

<div class="container">

    <div class="card border-0 my-5 text-center">
        <div class="card-body">
            <p class="card-text text-justify">É política da Fing Sistemas respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar em nosso site, softwares e aplicativos que possuímos.</p>
            <p class="card-text text-justify">Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>
            <p class="card-text text-justify">Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, os protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
            <p class="card-text text-justify">Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
            <p class="card-text text-justify">Os nossos softwares e aplicativos não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas que as empresas adotam e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
            <p class="card-text text-justify">Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
            <p class="card-text text-justify">O uso continuado de nosso site, softwares ou aplicativos será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>
        </div>
    </div>

</div>