import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { OrcamentoComponent } from 'src/app/pages/orcamento/orcamento.component';

@Component({
  selector: 'app-solicitar-orcamento',
  templateUrl: './solicitar-orcamento.component.html',
  styleUrls: ['./solicitar-orcamento.component.scss']
})
export class SolicitarOrcamentoComponent implements OnInit {

  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  solicitarOrcamento() {
    this.bsModalRef = this.modalService.show(OrcamentoComponent,{class: 'modal-lg'});
  }

}
