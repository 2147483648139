import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AlertComponent } from './alert/alert.component';
import { SolicitarOrcamentoComponent } from './solicitar-orcamento/solicitar-orcamento.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    SolicitarOrcamentoComponent    
  ],
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule.forRoot(),
    ModalModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    SolicitarOrcamentoComponent
  ]
})
export class SharedModule { }
