<header class="header">
    <div class="navbar navbar-light bg-white navbar-expand-md">
        <div class="container">
            <a class="navbar-brand" href="" (click)="collapse()" [routerLink]="['/']" >
                <img src="assets/logo.png" alt="FING">
            </a>
            <a class="navbar-toggler" (click)="isCollapsed = !isCollapsed" aria-controls="site-nav" [attr.aria-expanded]="!isCollapsed" >
                <i class="fas fa-bars "></i>
            </a>
            <div class="collapse navbar-collapse" [collapse]="isCollapsed" [isAnimated]="true" id="site-nav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"><a class="nav-link" (click)="collapse()" [routerLink]="['/solucoes']">Soluções</a></li>
                    <li class="nav-item"><a class="nav-link" (click)="collapse()" [routerLink]="['/produtos']">Produtos</a></li>
                    <li class="nav-item"><a class="nav-link" (click)="collapse()" [routerLink]="['/sobre']">Sobre</a></li>
                    <li class="nav-item"><a class="nav-link" (click)="collapse()" [routerLink]="['/contato']">Contato</a></li>
                </ul>
            </div>
        </div>
    </div>
</header>
