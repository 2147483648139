<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Sobre a <b>Fing Sistemas</b></h2>
            <p class="text-secondary">Saiba um pouco mais sobre nossa empresa</p>
        </div>
    </div>
</div>

<div class="container">
    <div class="card border-0 my-5 text-center">
        <div class="card-body">
            <h3 class="card-title">A empresa</h3>
            <p class="card-text text-justify">A Fing Sistemas é uma empresa inovadora que desenvolve um sistema de gestão empresarial, com o objetivo de oferecer para o mercado um produto moderno e intuitivo com um custo acessível. Estamos sediados em Santa Catarina, mas nossas soluções estão ajudando organizações de todo o Brasil.</p>
            <p class="card-text text-justify">Nosso software é totalmente baseado em nuvens, e por isso não requer uma equipe de especialistas em Tecnologia da Informação, pois entregamos à nossos clientes um produto completo e pronto para o uso, deixando-os com mais tempo para as operacões principais da organização e focados na melhoria dos resultados.</p>
        </div>
    </div>
    <div class="text-center">
        <img src="assets/img-sobre.jpg"  class="img-fluid" alt="Empresa">
    </div>
</div>

<div class="container">
    <div class="section text-center">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <h4>Missão</h4>                
                <h4 class="font-weight-bold">Contribuir para uma melhor gestão das organizações por meio de soluções tecnológicas completas.</h4>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-8">
                <h4>Visão</h4>
                <h4 class="font-weight-bold">Ser reconhecida por suas soluções inovadoras e confiáveis.</h4>
            </div>
        </div>
    </div>
</div>

<div class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mb-5">
                <h1 class="display-4 font-weight-bold mb-0">Mais de 350 mil</h1>
                <small>de Notas Fiscais Eletrônicas emitidas</small>
            </div>
            <div class="col-md-6 mb-5">
                <h1 class="display-4 font-weight-bold mb-0">Mais de 750 mil</h1>
                <small>de Conhecimentos de Transporte emitidos</small>
            </div>
            <div class="col-md-6 mb-5">
                <h1 class="display-4 font-weight-bold mb-0">Mais de 300 mil</h1>
                <small>de Manifestos Eletrônicos emitidos</small>
            </div>
            <div class="col-md-6">
                <h1 class="display-4 font-weight-bold mb-0">Mais de 1 bilhão</h1>
                <small>em valores de Documentos Fiscais</small>
            </div>
        </div>
    </div>
</div>

<div class="section text-center">
    <div class="container">
        <h2>Nossos Clientes</h2>
        <p>Estes são alguns de nossos clientes</p>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-4">
                <img src="assets/clientes-italia.png" alt="Fontanella" class="img-fluid">
            </div>
            <div class="col-sm-6 col-md-3 p-4">
                <img src="assets/clientes-oceanosul.png" alt="Oceano Sul" class="img-fluid">
            </div>
            <div class="col-sm-6 col-md-3 p-4">
                <img src="assets/clientes-pedroso.png" alt="Pedroso" class="img-fluid">
            </div>
            <div class="col-sm-6 col-md-3  p-4"> 
                <img src="assets/clientes-quimicer.png" alt="Quimicer" class="img-fluid">
            </div>
        </div>
    </div>
</div>

<div class="section bg-light text-center">
    <div class="container">
        <h3>Nossa Localização</h3>
        <p>Nossa empresa está localizada na cidade de Içara em Santa Catarina</p>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d431.1170602695024!2d-49.29959577979298!3d-28.716505948805267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95217e9420815473%3A0x5e7d34f167c837f9!2sFing%20Sistemas!5e0!3m2!1spt-BR!2sbr!4v1584994047046!5m2!1spt-BR!2sbr" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
</div>

<app-solicitar-orcamento></app-solicitar-orcamento>