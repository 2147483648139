import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { OrcamentoComponent } from '../orcamento/orcamento.component';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html'
})
export class ProdutosComponent implements OnInit {

  bsModalRef: BsModalRef;
  
  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {}

  solicitarOrcamento() {
    this.bsModalRef = this.modalService.show(OrcamentoComponent);
  }

}
