import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html'
})
export class PrivacidadeComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {}

}
