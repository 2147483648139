<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Conte com nossas <b>Soluções</b></h2>
            <p class="text-secondary">Como nossos produtos ajudam as empresas.</p>
        </div>
    </div>
</div>

<div class="container">
    
    <div class="row align-items-center mb-5" id="online">
        <div class="col-md-6 text-center">
            <img src="assets/solucoes-online.png" class="img-fluid" alt="ERP Online">
        </div>
        <div class="col-md-6 order-md-2">
            <h2 class="text-primary mb-4">ERP Online</h2>
            <p>Os produtos da Fing funcionam online, na <b>nuvem</b>, trazendo mais segurança e menos custos operacionais.</p>
            <p>Nossos produtos se <b>integram</b> de forma perfeita para oferecer uma solução abrangente que administra toda a sua empresa, incluindo vendas, compras, estoque e finanças. Contamos ainda com os produtos transporte e frota para transportadoras.</p>
            <p>Você terá uma <b>visão completa</b> de sua organização para poder gerar os melhores resultados.</p>
        </div>
    </div>

    <div class="row align-items-center mb-5" id="seguranca">
        <div class="col-md-6 order-2 order-md-1">
            <h2 class="text-primary mb-4">Segurança</h2>
            <p>No modelo de funcionamento em Nuvem você não precisa se preocupar com servidores, infraestrutura de TI e segurança, e ainda pode acompanhar sua empresa de qualquer lugar e em vários dispositivos.</p>
            <p>Nossos produtos proporcionam flexibilidade na gestão de segurança, com controle de usuários e acessos, e ainda com configuração de restrição por IP ou horário de acesso.</p>
        </div>
        <div class="col-md-6 order-1 order-md-2 text-center">
            <img src="assets/solucoes-seguranca.png" class="img-fluid" alt="Segurança">
        </div>        
    </div>

    <div class="row align-items-center mb-5" id="otimizacao-e-simplificacao">
        <div class="col-md-6 text-center">
            <img src="assets/solucoes-otimizacao.png" class="img-fluid" alt="Otimização e Simplificação">
        </div>
        <div class="col-md-6 order-md-2">
            <h2 class="text-primary mb-4">Otimização e Simplificação</h2>
            <p>Com nossos produtos você pode fornecer à sua equipe ferramentas para economizar tempo e trabalhar melhor, pois tratamos a complexibilidade tributária e a complexibilidade de processos.</p>
            <p>Fornecemos uma interface amigável e intuitiva, com fácil navegação e com possibilidade de adaptabilidade de processos à necessidade do cliente.</p>
        </div>
    </div>

    <div class="row align-items-center mb-5" id="integracao">
        <div class="col-md-6 order-2 order-md-1">
            <h2 class="text-primary mb-4">Integração</h2>
            <p>A integração contábil permite mais rapidez, segurança e agilidade na troca de informações. Exporte para sistemas contábeis todos os seus lançamentos, parametrizados com o plano de contas e os dados fiscais.</p>
            <p>Você não precisa se preocupar em guardar, organizar e mandar documentos físicos para seu contador. Com a integração, em apenas alguns passos, as informações são enviadas para a Contabilidade.</p>
        </div>
        <div class="col-md-6 order-1 order-md-2 text-center">
            <img src="assets/solucoes-integracao.png" class="img-fluid" alt="Integração">
        </div>        
    </div>
    
</div>

<app-solicitar-orcamento></app-solicitar-orcamento>