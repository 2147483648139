import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { AlertService } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html'
})
export class ContatoComponent implements OnInit {

  formulario: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      nome: [null, [Validators.required]],
      empresa: [null],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null],
      mensagem: [null, Validators.required]
    });
  }

  onSubmit(){
    if (this.formulario.valid) {
      console.log(this.formulario.value);
      this.http.post('https://www.fingerp.com.br/api/?/site/contato', JSON.stringify(this.formulario.value))
        .subscribe((dados: any) => {
          console.log(dados);
          if (dados.success) {
            this.alert.success(dados.data);
            this.formulario.reset();
          } else {
            this.alert.error(dados.message);
          }                    
        })
    } else {
      Object.keys(this.formulario.controls).forEach(campo => {
        const controle = this.formulario.get(campo);
        controle.markAsDirty();
        controle.markAsTouched();
      });
    }    
  }

  isInvalid(campo: string){
    return {
      'is-invalid': !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    }
  }

}
