<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Faça mais com nossos <b>Produtos</b></h2>
            <p class="text-secondary">Conheça todos os nossos produtos, como o Finanças e o Vendas.</p>
        </div>
    </div>
</div>

<div class="container">

    <div class="row align-items-center mb-5" id="financas">
        <div class="col-md-6 text-center">
            <img src="assets/produtos-financas.png" class="img-fluid" alt="Finanças">
        </div>
        <div class="col-md-6 order-md-2">
            <h2 class="text-primary mb-4">Finanças</h2>
            <p><b>Contas à Pagar</b>, com controle de liberação, possibilidade de pagamentos parciais, agrupamentos de documentos, emissão de recibos e integração com pagamento bancário eletrônico.</p>
            <p><b>Contas à Receber</b>, com possibilidade de recebimentos parciais, agrupamento de documentos, emissão de boletos bancários e integração completa de cobrança bancária.</p>
            <p>Controle de <b>Adiantamentos</b> de fornecedores e clientes.</p>
            <p>Gestão de várias <b>Contas</b> e controle de transferências entre contas.</p>
            <p><b>Conciliação Bancária</b> para garantir a integridade dos lançamentos bancários.</p>
            <p>Gerenciamento completo de <b>Cheques</b> emitidos e recebidos.</p>
            <p>Diversos <b>Relatórios</b> como DRE gerencial, fluxo de caixa previsto e realizado, extratos, saldos, contas por centros e elementos de custo, entre outros.</p>
        </div>
    </div>

    <div class="row align-items-center mb-5" id="vendas">
        <div class="col-md-6 order-2 order-md-1">
            <h2 class="text-primary mb-4">Vendas</h2>
            <p>Gestão de <b>Tabelas de Preços</b> de venda.</p>
            <p>Controle de <b>Orçamentos</b> com possibilidade de efetivação para um pedido.</p>
            <p>Gestão completa de <b>Pedidos</b>, com controle de liberação e possibilidade de faturamento integral ou parcial.</p>
            <p>Emissão de <b>NF-e</b> e <b>NFC-e</b>, com gerenciamento completo e emissão de cartas de correção eletrônicas.</p>
            <p>Emissão de <b>Romaneio</b> de carregamento.</p>
            <p>Controle completo de <b>Comissões</b> de vendas por representante.</p>
        </div>
        <div class="col-md-6 order-1 order-md-2 text-center">
            <img src="assets/produtos-vendas.png" class="img-fluid" alt="Vendas">
        </div>        
    </div>

    <div class="row align-items-center mb-5" id="compras">
        <div class="col-md-6 text-center">
            <img src="assets/produtos-compras.png" class="img-fluid" alt="Compras">
        </div>        
        <div class="col-md-6">
            <h2 class="text-primary mb-4">Compras</h2>
            <p>Controle de <b>Solicitações</b> de compras e <b>Cotações</b> de preços.</p>
            <p>Gerenciamento de <b>Ordens de Compra</b> com controle de liberação e possibilidade de efetivação parcial ou integral.</p>
            <p>Entrada de <b>Notas Fiscais</b> e <b>Conhecimentos</b> por lançamento manual, importação de XML ou captura direta do Sefaz.</p>
            <p>Emissão dos eventos de <b>Manifestações do Destinatário</b> da NF-e, e também dos eventos do CT-e.</p>
            <p>Possibilidade do controle de <b>Recebimentos de Materiais</b> parciais.</p>
        </div>
    </div>

    <div class="row align-items-center mb-5" id="estoque">
        <div class="col-md-6 order-2 order-md-1">
            <h2 class="text-primary mb-4">Estoque</h2>
            <p>Controle dos <b>Produtos</b> por grupo e subgrupo.</p>
            <p>Lançamentos de <b>Estoque</b> integrados automaticamente com as compras e as vendas.</p>
            <p>Controle de estoque por <b>Locais de Estoque</b>, com possibilidade de transferências e acertos.</p>
            <p>Cálculo de <b>Custos</b> de reposição e médio.</p>
            <p>Emissão de <b>Requisições</b> de materiais.</p>
            <p>Gerenciamento de <b>Ordens de Produção</b>.</p>            
        </div>
        <div class="col-md-6 order-1 order-md-2 text-center">
            <img src="assets/produtos-estoque.png" class="img-fluid" alt="Estoque">
        </div>        
    </div>

    <div class="row align-items-center mb-5" id="transporte">
        <div class="col-md-6 text-center">
            <img src="assets/produtos-transporte.png" class="img-fluid" alt="Transporte">
        </div>        
        <div class="col-md-6">
            <h2 class="text-primary mb-4">Transporte</h2>
            <p>Gerenciamento de <b>Tabelas de Frete</b> por cidades, microrregiões, mesorregiões e estados.</p>
            <p>Acompanhamento das <b>Cargas</b> desde a coleta, chegada da Nota Fiscal, até a entrega.</p>
            <p>Controle dos <b>Fretes</b> com montagem da carga, faturamento e emissão de contrato de frete.</p>
            <p>Emissão e gerenciamento completo dos documentos fiscais <b>CT-e</b> e <b>MDF-e</b>.</p>
            <p>Emissão do <b>CIOT</b> e integração com os meios de pagamentos eletrônicos de frete.</p>
            <p><b>Averbação</b> automática através de integração com seguradoras.</p>
            <p>Controle de <b>Adiantamentos</b> à motoristas e <b>Acertos</b> de viagens.</p>
            <p>Integração com embarcadores através de <b>EDI</b>.</p>            
        </div>
    </div>

    <div class="row align-items-center mb-5" id="frota">
        <div class="col-md-6 order-2 order-md-1">
            <h2 class="text-primary mb-4">Frota</h2>
            <p>Gestão de <b>Veículos</b> com controle de engates e checklists.</p>
            <p>Gerenciamento das <b>Multas</b> integrado ao financeiro.</p>
            <p>Controle de <b>Abastecimentos</b>.</p>
            <p>Gerenciamento de <b>Pneus</b>.</p>
            <p>Controle de <b>Ordens de Serviço</b>.</p>            
            <p>Gerenciamento de <b>Manutenções Preventivas</b>.</p>            
        </div>
        <div class="col-md-6 order-1 order-md-2 text-center">
            <img src="assets/produtos-frota.png" class="img-fluid" alt="Frota">
        </div>        
    </div>

</div>

<app-solicitar-orcamento></app-solicitar-orcamento>