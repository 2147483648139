<div class="jumbotron bg-white text-primary">
    <div class="container">
        <div class="text-center">
            <h2>Faça parte da <b>Fing</b></h2>
            <p class="text-secondary">Buscamos pessoas talentosas para somar à nossa equipe</p>
        </div>
    </div>
</div>

<div class="container">
    
    <div class="card border-0 my-5 text-center">
        <div class="card-body">
            <h3 class="card-title">O que nós fazemos</h3>
            <p class="card-text text-justify">A Fing Sistemas oferece à seus clientes um sistema de gestão empresarial completo e integrado que possibilita uma melhor gestão do seu negócio. E para isso utilizamos as melhores tecnologias e ferramentas disponíveis atualmente.</p>
        </div>   
    </div>
    
    <div class="text-center">
        <img src="assets/img-carreiras.jpg" class="img-fluid" alt="Carreiras">
    </div>   

    <div class="card border-0 my-5 text-center">
        <div class="card-body">
            <h3 class="card-title">Oportunidades</h3>
            <p class="card-text text-justify">Somos uma equipe pequena mas somos apaixonados pelo que fazemos, e contratamos somente pessoas que também amam o que fazem. Ser parte da equipe Fing significa que você irá trabalhar junto à outras pessoas para ajudar empresas em todo o Brasil a alcançarem suas metas. À medida que nossa base de clientes continua a crescer, estamos sempre na busca por pessoas talentosas para que com suas habilidades e criatividade possamos melhorar nossa equipe.</p>
            <a class="btn btn-primary btn-lg" [routerLink]="" (click)="enviarCurriculo()">Envie seu curriculo</a>
        </div>
    </div>

</div>