<footer class="footer">
    
    <div class="footer-sitemap">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <a [routerLink]="['/']"><img src="assets/logofooter.png" alt="FING" class="logo" /></a>
                </div>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-md-4">
                            <span class="title"><a [routerLink]="['/solucoes']">Soluções</a></span>
                            <ul>
                                <li><a [routerLink]="['/solucoes']" fragment="online">ERP Online</a></li>
                                <li><a [routerLink]="['/solucoes']" fragment="seguranca">Segurança</a></li>
                                <li><a [routerLink]="['/solucoes']" fragment="otimizacao-e-simplificacao">Otimização e Simplificação</a></li>
                                <li><a [routerLink]="['/solucoes']" fragment="integracao">Integração</a></li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <span class="title"><a [routerLink]="['/produtos']">Produtos</a></span>
                            <ul>
                                <li><a [routerLink]="['/produtos']" fragment="financas">Finanças</a></li>
                                <li><a [routerLink]="['/produtos']" fragment="vendas">Vendas</a></li>
                                <li><a [routerLink]="['/produtos']" fragment="compras">Compras</a></li>
                                <li><a [routerLink]="['/produtos']" fragment="estoque">Estoque</a></li>
                                <li><a [routerLink]="['/produtos']" fragment="transporte">Transporte</a></li>
                                <li><a [routerLink]="['/produtos']" fragment="frota">Frota</a></li>
                            </ul>
                        </div>                        
                        <div class="col-md-4">
                            <span class="title"><a href="">Empresa</a></span>
                            <ul>
                                <li><a [routerLink]="['/sobre']">Sobre</a></li>
                                <li><a [routerLink]="['/carreiras']">Carreiras</a></li>
                                <li><a [routerLink]="['/contato']">Contato</a></li>
                                <li><a [routerLink]="['/privacidade']">Privacidade</a></li>
                            </ul>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="footer-social">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <ul class="phone-links">
                        <li><a href="tel:+554830450661"><i class="fa fa-phone"></i> (48) 3045-0661</a></li>
                        <li><a href="mailto:fing@fing.com.br"><i class="fa fa-envelope"></i> fing@fing.com.br</a></li>
                    </ul>
                </div>
                <div class="col-sm-6 text-right">
                    <ul class="social-links">
                        <li><a href="http://www.facebook.com/fingsistemas" target="_new" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="http://www.twitter.com/fingsistemas" target="_new" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="http://www.linkedin.com/company/fing-sistemas" target="_new" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="http://www.youtube.com/fingsistemas" target="_new" title="Youtube"><i class="fab fa-youtube"></i></a></li>
                    </ul>                    
                </div>
            </div>
        </div>
    </div>
    <div class="footer-foot" >
        <div class="container">
            <small>&copy; Copyright 2022 Fing Sistemas Ltda. Todos os direitos reservados.</small>
        </div>
    </div>
</footer>