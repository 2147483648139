import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { CarreirasComponent } from './pages/carreiras/carreiras.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { PrivacidadeComponent } from './pages/privacidade/privacidade.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { SolucoesComponent } from './pages/solucoes/solucoes.component';
import { SobreComponent } from './pages/sobre/sobre.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'carreiras', component: CarreirasComponent },
    { path: 'contato', component: ContatoComponent },
    { path: 'privacidade', component: PrivacidadeComponent },
    { path: 'produtos', component: ProdutosComponent },
    { path: 'sobre', component: SobreComponent },
    { path: 'solucoes', component: SolucoesComponent },
    { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    scrollOffset: [0, 90],
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
