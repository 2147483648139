<div class="jumbotron bg-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 text-center">
                <h1>&nbsp;</h1>
                <h1 class="text-primary">Um sistema de gestão <span class="font-weight-bold">inteligente</span> para sua empresa</h1>
                <h1>&nbsp;</h1>
                <h1><a class="btn btn-primary btn-lg" [routerLink]="" (click)="solicitarOrcamento()">Solicite Orçamento</a></h1>
            </div>
        </div>
    </div>
</div> 

<div class="container">
    <div class="card card-section border-0 bg-dark text-white my-5" >
        <img src="assets/home-produto.jpg" class="card-img" alt="Nossos Produtos">
        <div class="card-img-overlay d-flex flex-column justify-content-center">
          <h2 class="card-title">Nossos Produtos</h2>
          <!--<p class="card-text">Estes são alguns de nossos produtos</p>-->
          <a class="text-white font-weight-bold stretched-link" [routerLink]="['/produtos']">Conheça nossos produtos</a>
        </div>
        <div class="card-body bg-white text-secondary">
            <h3 class="card-title">Nossos Produtos</h3>
            <!--<p class="card-text">Estes são alguns de nossos produtos</p>-->
            <a class="text-primary font-weight-bold stretched-link" [routerLink]="['/produtos']">Conheça nossos produtos</a>
          </div>
    </div>

    <div class="card card-section border-0 bg-dark text-white my-5">
        <img src="assets/home-solucoes.jpg" class="card-img" alt="Soluções">
        <div class="card-img-overlay d-flex flex-column justify-content-center">
          <h2 class="card-title">Soluções</h2>
          <!--<p class="card-text">Estas são algumas de nossas soluções</p>-->
          <a class="text-white font-weight-bold stretched-link" [routerLink]="['/solucoes']">Conheça nossas soluções</a>
        </div>
        <div class="card-body bg-white text-secondary">
            <h3 class="card-title">Soluções</h3>
            <!--<p class="card-text">Estas são algumas de nossas soluções</p>-->
            <a class="text-primary font-weight-bold stretched-link" [routerLink]="['/solucoes']">Conheça nossas soluções</a>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <div class="card border-0 my-5">
                <img src="assets/img-carreiras.jpg" class="card-img-top" alt="Carreiras">
                <div class="card-body">
                    <h3 class="card-title">Carreiras</h3>
                    <a class="text-primary stretched-link" [routerLink]="['/carreiras']">Junte-se  a nós</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card border-0 my-5 card">
                <img src="assets/img-contato.jpg" class="card-img-top" alt="Contato">
                <div class="card-body">
                    <h3 class="card-title">Contato</h3>
                    <a class="text-primary stretched-link" [routerLink]="['/contato']">Vamos conversar?</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-solicitar-orcamento></app-solicitar-orcamento>