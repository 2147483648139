import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { OrcamentoComponent } from '../orcamento/orcamento.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }
  
  solicitarOrcamento() {
    this.bsModalRef = this.modalService.show(OrcamentoComponent,{class: 'modal-lg'});
  }

}
