import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-curriculo',
  templateUrl: './curriculo.component.html'
})
export class CurriculoComponent implements OnInit {

  file: File;
  fileBase64: string;
  fileName: string = 'Selecionar Arquivo';

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
  }

  onChange(event){
    const selectedFiles = <FileList>event.srcElement.files;
    if (selectedFiles.length>0) {
      const max_size = 20000000;
      if (selectedFiles[0].size > max_size) {;
        this.alert.info("O arquivo deve ter no máximo " + max_size/1000000 + " Mb");
        return false;
      }
      this.file = selectedFiles[0];
      this.fileName = selectedFiles[0].name;  
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileBase64Path = e.target.result;
        this.fileBase64 = fileBase64Path;
      };
      reader.readAsDataURL(selectedFiles[0]);
    } else {
      this.file = null;
      this.fileBase64 = null;
      this.fileName = "Selecionar Arquivo";
    }
  }

  onUpload(){
    if (this.fileBase64 != null) {
      this.http.post('https://www.fingerp.com.br/api/?/site/curriculo', { 'file': this.fileBase64, 'filename': this.fileName })
      .subscribe((data: any) => {
        console.log(data);
        if (data.success) {
          this.bsModalRef.hide();
          this.alert.success(data.data);            
        } else {
          this.alert.error(data.message);
        }                    
      })
    }
  }

}
