import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertComponent } from './alert.component';

export enum AlertTypes {
  SUCCESS = 'success',
  ERROR = 'danger',  
  INFO = 'warning'
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private modalService: BsModalService
  ) { }

  private show(message: string, type: AlertTypes, dismissTimeout?: number){
    const bsModalRef: BsModalRef = this.modalService.show(AlertComponent, { class: 'modal-dialog-centered' });
    bsModalRef.content.type = type;
    bsModalRef.content.message = message;
    if (dismissTimeout) {
      setTimeout(() => bsModalRef.hide(), dismissTimeout);
    }
  }

  public success(message: string){
    this.show(message,AlertTypes.SUCCESS,5000);
  }

  public error(message: string){
    this.show(message,AlertTypes.ERROR);
  }

  public info(message: string){
    this.show(message,AlertTypes.INFO);
  }

}
